import React from 'react';

import { Board, ExportBlock } from '@/Components/Partials';
import { Button, Icon } from '@/Components/UI';
import { Tools } from '@/Services';

import { DeviceProps } from './types';
import { useDeviceCard } from './useDeviceCard';

export const DeviceCard: React.FC<DeviceProps> = ({ device, onClickEditLicenseKey }) => {
    const { isLoading, modal, showChambers } = useDeviceCard({
        deviceId: device.id,
    });

    return (
        <Board.Item className="station-firmware__item">
            <h2>{device.type.name}</h2>
            <div>
                <h3>Device ID</h3>
                <p>{device.id}</p>
            </div>
            <div>
                <h3>UDI</h3>
                <p>{device.identifier}</p>
            </div>
            <div>
                <h3>Current WLM firmware version</h3>
                <p>
                    {device.wlm_firmware_version.name}
                    {device.wlm_firmware_version.is_outdated ? <Icon name="warning" size={15} /> : <Icon name="green-checkmark" size={15} />}
                </p>
            </div>

            <div>
                <h3>Current LMP firmware version</h3>
                <p>
                    {device.lmp_firmware_version.name}
                    {device.lmp_firmware_version.is_outdated ? <Icon name="warning" size={15} /> : <Icon name="green-checkmark" size={15} />}
                </p>
            </div>
            <div>
                <h3>MAC address</h3>
                <p>{device.mac_address}</p>
            </div>
            <div>
                <h3>M-Sector UDI</h3>
                <p>{device.m_sector_udi}</p>
            </div>
            <div>
                <h3>LMP HW version</h3>
                <p>{device.lmp_hardware_version}</p>
            </div>
            <div>
                <h3>Battery Status</h3>
                <p>{device.battery && `${device.battery}%`}</p>
            </div>
            <div>
                <h3>Last update</h3>
                <p>{device.updated_at && Tools.date.autoFormat(device.updated_at * 1000)}</p>
            </div>
            <div>
                <h3>License key</h3>
                {device.license_key ? <p className="license_key">{device.license_key}</p> : <p className="error">Not specified</p>}
                <Button color="transparent" className="edit" onClick={() => onClickEditLicenseKey(device)}>
                    Edit license key
                </Button>
            </div>

            <Button disabled={isLoading || !device.has_chambers} color="brown" onClick={showChambers}>
                Chambers
            </Button>

            <ExportBlock className="station-firmware_export" model="device_chambers" filters={{ device_id: device.id }} />

            {modal}
        </Board.Item>
    );
};
